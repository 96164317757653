<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <ul class="pagination">
    <li
      class="page-item"
        :class="{ active: page === current }"
      v-for="page in pages"
      :key="page"
    >
      <span
        class="page-link pointer"
        :class="{ 'bg-white': page !== current }"
        @click="onPageClicked(page)"
      >
        {{ page }}
      </span>
    </li>
  </ul>
</template>
<script>

export default {
  name: 'Paginator',
  props: {
    page: Number,
    current: Number,
    pages: Number
  },
  methods: {
    onPageClicked(page) {
      if (page == this.current) {
        return
      }

      this.$emit('page', page)
    }
  }
}

</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
