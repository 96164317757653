// SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>
//
// SPDX-License-Identifier: AGPL-3.0-or-later

export default {
  namespaced: true,
  state() {
    return {
      loading: false,
      showSpinner: false,
      profileId: null,
      profile: null,
      isOwnProfile: false,
      error: false,
      notFound: false
    }
  },
  mutations: {
    setProfileId(state, profileId) {
      state.profileId = profileId
    },
    clearProfileId(state) {
      state.profileId = null
    },
    setProfile(state, profile) {
      state.profile = profile
    },
    clearProfile(state) {
      state.profile = null
    },
    setLoading(state) {
      state.loading = true
    },
    setNotLoading(state) {
      state.loading = false
    },
    setError(state) {
      state.error = true
    },
    clearError(state) {
      state.error = false
    },
    showSpinner(state) {
      state.showSpinner = true
    },
    hideSpinner(state) {
      state.showSpinner = false
    },
    setNotFound(state, notFound) {
      state.notFound = notFound
    },
    setIsOwnProfile(state, isOwnProfile) {
      state.isOwnProfile = isOwnProfile
    }
  },
  actions: {
    onError({commit, dispatch}) {
      commit('setError')
      dispatch('clear')
    },
    onNotFound({commit, dispatch}) {
      dispatch('onError')
      commit('setNotFound', true)
    },
    clear({commit}) {
      commit('clearProfileId')
      commit('clearProfile')
      commit('hideSpinner')
      commit('setNotLoading')
    },
    async load({state, commit, dispatch, rootState}, profileId) {
      if (state.loading) {
        return
      }
      
      commit('setProfileId', profileId)
      commit('setIsOwnProfile', rootState.currentUserId === profileId)
      commit('setLoading')

      const timeoutId = setTimeout(() => {
        commit('showSpinner')
        commit('clearProfile')
      }, 0)

      commit('clearError')
      commit('setNotFound', false)

      const url = new URL(`${window.ki.apiUrl}/users/${profileId}/profile`)
      const headers = {
        Authorization: `Bearer ${rootState.token}`
      }

      let response

      try {
        response = await fetch(url, {headers})
      } catch {
        dispatch('onError')
        return
      }

      clearTimeout(timeoutId)

      if (response.status === 404) {
        dispatch('onNotFound')
        return
      }

      if (!response.ok) {
        dispatch('onError')
        return
      }

      const responseData = await response.json()
      commit('setProfile', responseData.profile)
      commit('hideSpinner')
      commit('setNotLoading')
    }
  }
}
