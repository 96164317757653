<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="container text-center py-5">
    <template v-if="notFound">
      <div v-if="isOwnProfile">
        <div class="fs-1 lh-1">nullptr :/</div>
        <div class="fs-3 mb-4">Du hast noch kein Profil</div>
        <router-link :to="{ name: 'ProfileEdit' }" class="btn btn-primary" >
          Jetzt Profil erstellen
        </router-link>
      </div>
      <div v-else>
        <div class="fs-1 mb-3">nullptr :/</div>
        <div class="mb-3">
          Profil nicht gefunden
        </div>
      </div>
    </template>
    <template v-else>
      <div class="fs-1 mb-3">Kernel panic :/</div>
      Das Profil konnte nicht geladen werden
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isOwnProfile: Boolean,
    notFound: Boolean
  }
}
</script>

