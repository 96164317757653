<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="skill rounded me-2">
    <div class="skill__left p-2">
      <div class="skill__icon" :style="{ backgroundImage: iconUrl }"></div>
    </div>
    <div class="skill__right d-flex align-items-center rounded-end px-2">
      <div>
        <div class="skill__name fw-bold me-1">
          {{ profileSkill.skill.name }}
        </div>
        <small class="skill__level" v-if="showLevel" :title="levelTitle">
          {{ level }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import levels from '@/assets/skill_level.json';

export default {
  props: {
    profileSkill: Object,
    showLevel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      levels
    }
  },
  computed: {
    iconUrl() {
      return `url("${window.ki.apiUrl}/${this.profileSkill.skill.icon_url}")`
    },
    level() {
      return levels[this.profileSkill.level].short
    },
    levelTitle() {
      return levels[this.profileSkill.level].long
    }
  }
}
</script>

<style>
.skill {
  align-items: stretch;
  border: 1px solid #acacac;
  display: inline-flex;
  line-height: 1;
}

.skill__icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 32px;
  width: 32px;
}

.skill__right {
  background-color: #edefeb;
  color: #202020;
}

.skill__name,
.skill__level {
  display: inline-block;
  white-space: nowrap;
}
</style>
