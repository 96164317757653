<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="contact rounded d-inline-flex align-items-center">
    <div class="contact__left px-2">
      {{ profileContact.contacttype.name }}
    </div>
    <div class="contact__right d-flex align-items-center rounded-end px-2">
      {{ profileContact.content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profileContact: Object,
  }
}
</script>

<style>
.contact {
  align-items: stretch;
  border: 1px solid #acacac;
  display: inline-flex;
}

.contact__right {
  background-color: #edefeb;
  color: #202020;
  font-weight: bold;
  height: 32px;
}
</style>
