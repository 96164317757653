<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="bg-wtf py-3">
    <div class="container">
      <div class="d-flex align-items-center mb-3">
        <Avatar class="me-3" :name="profile.nickname" />
        <div class="text-white fs-3">
          <span class="fs-3">{{ profile.nickname }}</span>
          <span v-if="profile.pronouns" class="fs-5">
            ({{ profile.pronouns }})
          </span>
        </div>
      </div>
      <div v-if="profile?.address?.name">
        <div class="d-flex align-items-center">
          <i class="fs-4 bi bi-person-fill text-dark mx-2"></i>
          <div class="text-white">
            a.k.a. {{ profile.address.name }}
          </div>
        </div>
      </div>
      <div v-if="location">
        <div class="d-flex align-items-center">
          <i class="fs-4 bi bi-geo-alt-fill text-dark mx-2"></i>
          <div class="text-white">
            {{ location }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from '@/components/Avatar'

export default {
  name: 'ProfileHeader',
  components: {
    Avatar
  },
  props: {
    profile: Object
  },
  computed: {
    location() {
      if (!this.profile.address) {
        return
      }

      const parts = []

      if (this.profile.address.postcode) {
        parts.push(this.profile.address.postcode)
      }

      if (this.profile.address.city) {
        parts.push(this.profile.address.city)
      }

      if (this.profile.address.country) {
        parts.push(this.profile.address.country)
      }

      return parts.join(', ')
    }
  }
}
</script>

<style>
.content {
  min-height: calc(100vh - 60px);
}
</style>
