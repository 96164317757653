<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
<footer class="bg-dark py-5">
  <div class="container">
    <div class="row text-center text-sm-start">
      <div class="col-sm-6 d-sm-flex justify-content-center mb-3 mb-sm-0">
        <div class="d-inline-block">
          <div class="fw-bold text-white mb-2">Kompetenzinventar</div>
          <ul class="list-unstyled">
            <li><a href="https://git.wtf-eg.de/kompetenzinventar">Quellcode</a></li>
            <li><a href="https://git.wtf-eg.de/kompetenzinventar/ki-doku/issues/new/choose">Problem melden</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 d-sm-flex justify-content-center">
        <div class="d-inline-block">
          <ul class="list-unstyled">
            <li><a href="https://wtf-eg.de/impressum/">Impressum</a></li>
            <li><a href="https://wtf-eg.de/datenschutz/">Datenschutzerklärung</a></li>
          </ul>
          <img class="wtf-logo wtf-logo--footer" src="@/assets/img/wtf_logo_white.svg">
        </div>
      </div>
    </div>
  </div>
</footer>
</template>
<style scoped>
a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

.wtf-logo--footer {
  max-width: 200px;
}
</style>
