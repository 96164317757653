// SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>
//
// SPDX-License-Identifier: AGPL-3.0-or-later

import { createStore } from 'vuex'

import profile from './profile'
import search from './search'

const localStorageKeys = {
  currentUserId: 'ki_current_user_id',
  token: 'ki_token',
}

export default createStore({
  modules: {
    profile,
    search,
  },
  state() {
    return {
      currentUserId: JSON.parse(localStorage.getItem(localStorageKeys.currentUserId)),
      token: JSON.parse(localStorage.getItem(localStorageKeys.token)),
    }
  },
  mutations: {
    clearCurrentUserId(state) {
      state.currentUserId = null
      localStorage.removeItem(localStorageKeys.currentUserId)
    },
    setCurrentUserId(state, currentUserId) {
      state.currentUserId = currentUserId
      localStorage.setItem(
        localStorageKeys.currentUserId, 
        JSON.stringify(currentUserId)
      )
    },
    clearToken(state) {
      state.token = null
      localStorage.removeItem(localStorageKeys.token)
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem(localStorageKeys.token, JSON.stringify(token))
    },
  },
  actions: {
    clear(context) {
      context.commit('clearCurrentUserId')
      context.commit('clearToken')
    }
  }
})
