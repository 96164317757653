<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div>
    <div class="bg-wtf py-3 mb-4">
      <div class="container">
        <h3 class="text-white text-center mb-0">Profil bearbeiten</h3>
      </div>
    </div>
    <div class="container">
      <form @submit.prevent="submitFormEdit(false)">
        <Section title="Grunddaten">
          <div class="row mb-4">
            <div class="col-12 col-md-4 mb-3 mb-md-0">
              <label class="form-label">Nickname</label>
              <input
                type="text"
                class="form-control"
                id="nickname"
                maxlength="25"
                v-model="profile.nickname"
                required
              />
            </div>
            <div class="col-12 col-md-4 mb-3 mb-md-0">
              <label class="form-label">Klarname (optional)</label>
              <input
                type="text"
                class="form-control"
                id="realname"
                maxlength="25"
                v-model="profile.address.name"
              />
            </div>
            <div class="col-12 col-md-4">
              <label class="form-label">
                Pronomen
                <i class="bi bi-info-circle" v-tooltip="pronounsTooltip"></i>
              </label>
              <input
                type="text"
                class="form-control"
                id="pronouns"
                maxlength="25"
                v-model="profile.pronouns"
              />
            </div>
          </div>

          <label class="form-label">Anschrift</label>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 mb-md-0">
              <input
                type="text"
                class="form-control"
                id="postcode"
                maxlength="10"
                placeholder="Postleitzahl"
                v-model="profile.address.postcode"
              />
            </div>
            <div class="col-12 col-md-4 mb-3 mb-md-0">
              <input
                type="text"
                class="form-control"
                id="city"
                maxlength="25"
                placeholder="Ort"
                v-model="profile.address.city"
              />
            </div>
            <div class="col-12 col-md-4">
              <input
                type="text"
                class="form-control"
                id="country"
                maxlength="25"
                placeholder="Land"
                v-model="profile.address.country"
              />
            </div>
          </div>
        </Section>

        <Section title="Meine Fähigkeiten">
          <template v-slot:card-body>
            <auto-complete
              type="skill"
              :values="profile.skills"
              placeholder="z.B. Python, JavaScript, Linux"
              @update-values="profile.skills = $event"
            ></auto-complete>
          </template>
        </Section>

        <Section title="Meine Sprachkenntnisse">
          <template v-slot:card-body>
            <auto-complete
              type="language"
              :values="profile.languages"
              placeholder="z.B. Deutsch, Englisch, Französisch"
              @update-values="profile.languages = $event"
            ></auto-complete>
          </template>
      </Section>

        <Section title="Ich suche für mich Projekte/Aufträge in folgenden Bereichen">
          <template v-slot:card-body>
            <auto-complete
              type="skill"
              label="Ich suche für mich Projekte/Aufträge in folgenden Bereichen"
              :values="profile.searchtopics"
              :showSecondary="false"
              placeholder="z.B. Python, JavaScript, Linux"
              @update-values="profile.searchtopics = $event"
            ></auto-complete>
          </template>
        </Section>

        <Section title="Verfügbarkeit">
          <div class="form-check mb-3">
            <input
              v-model="profile.availability_status"
              class="form-check-input me-2"
              type="checkbox"
              id="availability_status">
              <label class="form-check-label" for="availability_status">
                Ich bin aktuell verfügbar
              </label>
            </div>
            <div class="mb-3" v-if="profile.availability_status">
              <label class="form-label">
                Stunden pro Woche
              </label>
              <input
                v-model="profile.availability_hours_per_week"
                type="number"
                min="0"
                max="168"
                class="form-control">
              </div>
              <label for="availability" class="form-label">
                Anmerkungen
              </label>
              <textarea
                class="form-control"
                id="availability"
                rows="3"
                maxlength="4000"
                v-model="profile.availability_text"
              ></textarea>
            </Section>

            <Section title="Meine Kontaktmöglichkeiten">
              <template v-slot:card-body>
                <auto-complete
                  type="contacttype"
                  :values="profile.contacts"
                  placeholder="z.B. E-Mail, Mobiltelefon, Matrix, Web"
                  @update-values="profile.contacts = $event"
                ></auto-complete>
              </template>
            </Section>

            <Section title="Sonstiges">
              <div class="mb-3 bg-white">
                <label class="form-label">Über mich</label>
                <textarea
                  class="form-control"
                  rows="3"
                  maxlength="4000"
                  v-model="profile.freetext"
                />
              </div>
              <div>
                <label class="form-label">Ehrenamtliche Arbeit</label>
                <textarea
                  class="form-control"
                  rows="3"
                  maxlength="4000"
                  v-model="profile.volunteerwork"
                />
              </div>
            </Section>
            <input type="submit" class="d-none">
          </form>
        </div>
        <div class="savebar bg-white border-top py-3">
          <div class="container d-flex align-items-center justify-content-end">
            <div
              class="text-danger"
              v-if="showErrorMessage"
            >
              <i class="bi bi-bug"></i>
              Beim Speichern ist ein Fehler aufgetreten.
            </div>
            <div
              class="text-success"
              v-if="showSuccessMessage"
            >
              <i class="bi bi-check-lg"></i>
              Gespeichert
            </div>
            <button
              class="btn btn-secondary ms-3"
              @click="submitFormEdit(false)"
            >
              Entwurf Speichern
            </button>
            <button
              class="btn btn-primary ms-3"
              @click="submitFormEdit(true)"
            >
              Speichern und Veröffentlichen
            </button>
          </div>
        </div>
      </div>
</template>
<script>
import RequestMixin from "@/mixins/request.mixin"

import AutoComplete from "@/components/AutoComplete";
import Section from '@/components/profile/Section'

export default {
  name: "profileEdit",
  mixins: [RequestMixin],
  components: {
    AutoComplete,
    Section,
  },
  data() {
    return {
      showErrorMessage: false,
      showSuccessMessage: false,
      clearMessagesHandle: null,
      profile: {
        visible: false,
        nickname: "",
        pronouns: "",
        volunteerwork: "",
        freetext: "",
        availability_status: false,
        availability_hours_per_week: null,
        availability_text: "",
        address: {
          postcode: "",
          city: "",
          country: "",
        },
        skills: [],
        languages: [],
        searchtopics: [],
        contacts: [],
      },
      pronounsTooltip: {
        content:  'Wie möchtest du angesprochen werden?<br>Zum Beispiel "er/ihn" oder "sie/ihre".',
        html: true
      }
    };
  },
  async created() {
    await this.initEditPage();
  },
  unmounted() {
    this.cancelClearMessages()
  },
  methods: {
    cancelClearMessages() {
      if (this.clearMessagesHandle) {
        window.clearTimeout(this.clearMessagesHandle)
      }
    },
    scheduleClearMessages() {
      this.cancelClearMessages()
      this.clearMessagesHandle = window.setTimeout(() => {
        this.showErrorMessage = false
        this.showSuccessMessage = false
      }, 2500)
    }
  },
  watch: {
    showErrorMessage(curr) {
      if (curr) {
        this.scheduleClearMessages()
      }
    },
    showSuccessMessage(curr) {
      if (curr) {
        this.scheduleClearMessages()
      }
    }
  }
};
</script>
<style scoped>
.savebar {
  bottom: 0;
  position: sticky;
}
</style>
