// SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>
//
// SPDX-License-Identifier: AGPL-3.0-or-later

import store from '@/store'

export default {
  methods: {
    async submitLogin() {
      this.showErrorMessage = false;
      try {
        const data = JSON.stringify({
          username: this.username,
          password: this.password,
        })

        const response = await fetch(`${this.apiUrl}/users/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: data
        })

        if (!response.ok) {
          this.showErrorMessage = true
          return
        }

        const responseData = await response.json()
        store.commit('setCurrentUserId', parseInt(responseData.user_id, 10))
        store.commit('setToken', responseData.token)
        this.$router.push({ path: '/s/search' });
      } catch (error) {
        console.error(error);
        this.showErrorMessage = true;
      }
    },
    async search() {
      if (!this.searchText) {
        this.searchResults = []
        return
      }

      try {
        const response = await fetch(`${this.apiUrl}/${this.type}s?search=${this.searchText}`, {
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            },
          }
        );

        if (!response.ok) {
          console.error();
          this.showErrorMessage = true;
          return
        }

        const responseData = await response.json()
        const searchResults = responseData[`${this.type}s`];

        if (
          !searchResults.map((item) => item.name.toLowerCase())
            .includes(this.searchText.toLowerCase())
        ) {
          searchResults.unshift({ name: this.searchText });
        }

        this.searchResults = searchResults
      } catch (error) {
        console.error();
        this.showErrorMessage = true;
      }
    },
    async initEditPage() {
      const userId = store.state.currentUserId
      const url = `${this.apiUrl}/users/${userId}/profile`

      try {
        const response = await fetch(url, {
            headers: { 
              Authorization: `Bearer ${store.state.token}` 
            },
          }
        );

        if (!response.ok) {
          return
        }

        const responseData = await response.json()
        
        this.profile = responseData.profile;
      } catch (error) {
        console.error(error);
      }
    },
    async submitFormEdit(isProfileVisible) {
      this.showErrorMessage = false
      this.showSuccessMessage = false
      const userId = store.state.currentUserId
      this.profile.visible = isProfileVisible;

      try {
        const body = JSON.stringify(this.profile)
        const response = await fetch(
          `${this.apiUrl}/users/${userId}/profile`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${store.state.token}`,
              'Content-Type': 'application/json',
            },
            body
          }
        );

        if (!response.ok) {
          this.showErrorMessage = true
          return
        }
        
        this.showSuccessMessage = true
      } catch (error) {
        console.error(error);
        this.showErrorMessage = true;
      }
    },
    async initViewPage() {
      try {
        const response = await fetch(`${this.apiUrl}/users/${this.$route.params.memberId}/profile`, {
            headers: { Authorization: `Bearer ${store.state.token}` },
          }
        );

        if (!response.ok) {
          return
        }

        const responseData = await response.json()
        store.commit('setCurrentProfile', responseData.profile)
      } catch (error) {
        console.error(error);
      }
    },
    async submitSearch() {
      this.showErrorMessage = false;

      try {
        const url = new URL(`${this.apiUrl}/users/profiles`)

        if (this.searchText != "") {
          url.searchParams.append('nickname', this.searchText)
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${store.state.token}`,
          },
        });

        if (!response.ok) {
          this.showErrorMessage = true
          return
        }

        const responseData = await response.json()

        this.searchResults = responseData.profiles;
        this.searchTotal = responseData.total;
      } catch (error) {
        console.error(error);
        this.showErrorMessage = true;
      }
    },
  },
}
