<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="content">
    <div class="bg-wtf text-white pt-3 pb-4">
      <div class="container">
        <div class="fs-3 text-center lh-1 mb-3">Finde WTF Member</div>
        <div class="card mx-auto bg-white">
          <div class="card-body">
            <form @submit.prevent="handleSubmit">
              <fieldset class="d-flex" :disabled="searching">
                <div class="flex-grow-1 me-3">
                  <input
                    type="text"
                    class="form-control"
                    id="searchText"
                    v-model="searchText"
                    placeholder="Nick, Name, Fähigkeit, Sprache"
                    ref="searchTextInput"
                  />
                </div>
                <div class="">
                  <button type="submit" class="btn btn-primary">
                    <i class="bi-search"></i>
                    <span class="d-none d-md-inline"> Suchen</span>
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-4 pb-3">
      <div class="text-center" v-if="showSpinner">
        <Spinner />
      </div>
      <div
        class="fs-2 text-danger text-center"
        role="alert"
        v-if="error"
      >
        <div class="fs-1 mb-3">Kernel panic :/</div>
        Bei der Suche ist ein Fehler aufgetreten.
      </div>
      <div v-else-if="showNoResults" class="fs-2 text-black-50 text-center">
        <div class="fs-1 mb-3">nullptr :/</div>
        Es wurde kein Suchergebnis gefunden.
        <p v-if="searchText !== ''">Probiere eine andere Suche.</p>
      </div>
      <div v-else-if="showResults">
        <div class="d-flex justify-content-around">
          <Paginator 
            :pages="pages" 
            :current="currentPage"
            @page="handlePageSelected"
          />
        </div>
        <SearchResult
          v-for="profile in profiles"
          :key="profile.user_id"
          class="mb-3"
          :profile="profile"
        />
        <div class="d-flex justify-content-around">
          <Paginator 
            :pages="pages" 
            :current="currentPage"
            @page="handlePageSelected"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Paginator from '@/components/Paginator'
import SearchResult from '@/components/SearchResult'
import Spinner from '@/components/Spinner'

export default {
  name: 'Search',
  components: {
    Paginator,
    SearchResult,
    Spinner,
  },
  data() {
    return {
      textChanged: false
    }
  },
  computed: {
    ...mapState({
      searching: state => state.search.searching,
      profiles: state => state.search.profiles,
      error: state => state.search.error,
      showSpinner: state => state.search.showSpinner,
      pages: state => state.search.pages,
    }),
      searchText: {
        get() {
          return this.$store.state.search.query.search
        },
        set(text) {
          this.$store.commit('search/setQuerySearch', text)
          this.textChanged = true
        }
      },
      currentPage: {
        get() {
          return this.$store.state.search.query.page
        },
        set(page) {
          this.$store.commit('search/setQueryPage', page)
        }
      },
      showNoResults() {
        return !this.searching && (!this.profiles || this.profiles.length === 0)
      },
      showResults() {
        return !this.error && this.profiles && this.profiles.length > 0
      }
  },
  watch: {
    searching(value) {
      if (!value) {
        if (this.$refs.searchTextInput) {
          this.focusSearchText()
        }
      }
    }
  },
  methods: {
    handleSubmit() {
      if (this.textChanged === true) {
        this.$store.commit('search/setQueryPage', 1)
      }
      this.pushState()
      this.$store.dispatch('search/search')
    },
    focusSearchText() {
      this.$nextTick(() => {
        this.$refs.searchTextInput.focus()
      })
    },
    handlePageSelected(page) {
      this.currentPage = page
      this.pushState()
      this.$store.dispatch('search/search')
    },
    pushState() {
      this.$router.push({ query: { query: this.searchText, page: this.currentPage }})
    }
  },
  created() {
    if (this.$route.query.query) {
      this.searchText = this.$route.query.query
      this.$store.commit('search/clearProfiles')
    }

    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page, 10)
      this.$store.commit('search/clearProfiles')
    }

    this.$store.dispatch('search/search')
  }
};
</script>
<style scoped>
.container {
  max-width: 768px;
}

.content {
  min-height: calc(100vh - 60px);
}
</style>
