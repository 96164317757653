<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <ul class="list-group list-group-flush">
    <li
      class="list-group-item bg-white"
      v-for="(value, valueKey) in values"
      :key="value.id"
    >
      <div class="row">
        <div class="col-12 col-md-5 d-flex align-items-center">
          <div
            class="list-icon me-1"
            :style="{ backgroundImage: `url('${iconBaseUrl + value[type].icon_url}'` }"
            v-if="value[type].icon_url"
          />
          <div>
            {{ value[type].name }}
          </div>
        </div>
        <div class="col-10 col-md-5">
          <div v-if="type == 'skill' && showSecondary">
            <select
              class="form-select form-select-sm"
              aria-label="Selektiere dein Level"
              v-model="editableValues[valueKey].level"
              @input="$emit('update-values', editableValues)"
            >
              <option
                v-for="(value, key) in levelSelection"
                :value="key"
                :key="key"
              >
                {{ value.long || value }}
              </option>
            </select>
          </div>
          <div v-else-if="type == 'language'">
            <select
              class="form-select form-select-sm"
              aria-label="Selektiere dein Level"
              v-model="editableValues[valueKey].level"
              @input="$emit('update-values', editableValues)"
            >
              <option
                v-for="(value, key) in languagesSelection"
                :value="key"
                :key="key"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <div v-else-if="type == 'contacttype'">
            <input
              class="form-control form-control-sm"
              maxlength="200"
              v-model="editableValues[valueKey].content"
            />
          </div>
        </div>
        <div class="col-2 text-end">
          <button
            type="button"
            class="btn btn-sm btn-light"
            aria-label="Löschen"
            @click="$emit('removeValue', value[type].name)"
          >
            <i class="text-danger bi bi-x-circle"></i>
          </button>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import levelJson from '@/assets/skill_level.json';
import languagesJson from '@/assets/language_level.json';

export default {
  name: 'ProfileList',
  props: {
    type: {
      type: String,
    },
    values: {
      type: Array,
      required: true,
    },
    showSecondary: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      iconBaseUrl: this.apiUrl,
      levelSelection: levelJson,
      languagesSelection: languagesJson,
      editableValues: this.values,
    };
  },
  watch: {
    values: function (newVal) {
      this.editableValues = newVal;
    },
  },
};
</script>

<style scope>
.list-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 32px;
  width: 32px;
}
</style>
