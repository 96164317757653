<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="bg-wtf">
    <div class="container pt-5">
      <div class="text-center mb-5">
        <img class="wtf-logo wtf-logo--index" src="@/assets/img/wtf_logo.svg">
        <h1 class="text-white">Kompetenzinventar</h1>
      </div>
      <form @submit.prevent="submitLogin()" class="card bg-white login-form">
        <div class="card-body">
          <div class="mb-3">
            <label for="exampleInputusername1" class="form-label" >
              WTF-Benutzername:
            </label>
            <input
              type="username"
              class="form-control"
              id="exampleInputusername1"
              v-model="username"
              required
              autofocus
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">Passwort:</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              v-model="password"
              required
            />
          </div>
          <button type="submit" class="btn btn-primary">Login</button>
          <a class="btn btn-link" href="https://resetpw.wtf-eg.de/">Passwort vergessen?</a>
          <div
            class="alert alert-danger mt-3 mb-0"
            role="alert"
            v-if="showErrorMessage"
          >
            Dein Benutzername oder Passwort ist falsch.<br>Versuche es noch einmal.
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import RequestMixin from "@/mixins/request.mixin"

export default {
  name: "Index",
  mixins: [RequestMixin],
  data() {
    return {
      showErrorMessage: false,
      username: "",
      password: "",
    };
  },
};
</script>

<style scoped>

.container {
  min-height: 100vh;
}

.login-form {
  margin: 0 auto;
  max-width: 500px;
}

.wtf-logo--index {
  max-width: 200px;
}
</style>
