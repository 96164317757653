<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <router-link
    class="text-decoration-none d-flex"
    :to="{ path: `/s/profile/${profile.user_id}` }"
  >
    <div class="card w-100 bg-white">
      <div class="card-body d-flex">
        <div class="d-flex align-items-center justify-content-center me-3">
          <Avatar :name="profile.nickname"/>
        </div>
        <div class="text-body">
        <h5 class="card-title mb-1 lh-1">
          {{ profile.nickname}}
          <span v-if="profile.pronouns"> ({{ profile.pronouns }})</span>
        </h5>
        <small
          class="card-text lh-1 text-dark"
          v-if="profile.skills && profile.skills.length > 0"
        >
          Top-Fähigkeiten: {{ topSkills }}
        </small>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import Avatar from '@/components/Avatar'

export default {
  components: {
    Avatar,
  },
  props: {
    profile: Object
  },
  computed: {
    topSkills() {
      return this.profile.skills.slice(0, 5).map(s => s.skill.name).join(', ')
    }
  }
}
</script>
<style scoped>
.card:hover {
  background-color: #f8f9fa;
}
</style>
