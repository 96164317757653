<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="language rounded me-2">
    <div class="language__left px-2">
      <div class="language__icon" :style="{ backgroundImage: iconUrl }"></div>
    </div>
    <div class="language__right d-flex align-items-center rounded-end px-2">
      <div>
        <div class="language__name me-1">{{ profileLanguage.language.name }}</div>
        <small class="language__level">{{ level }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import levels from '@/assets/language_level.json';

export default {
  props: {
    profileLanguage: Object,
  },
  data() {
    return {
      levels
    }
  },
  computed: {
    iconUrl() {
      return `url("${window.ki.apiUrl}/${this.profileLanguage.language.icon_url}")`
    },
    level() {
      return levels[this.profileLanguage.level]
    }
  }
}
</script>

<style>
.language {
  align-items: stretch;
  border: 1px solid #acacac;
  display: inline-flex;
}

.language__icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 32px;
  width: 32px;
}

.language__right {
  background-color: #edefeb;
  color: #202020;
}

.language__name {
  display: inline-block;
  font-weight: bold;
  white-space: nowrap;
}
</style>
