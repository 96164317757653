<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div>
    <template v-if="error">
      <ViewError :isOwnProfile="isOwnProfile" :notFound="notFound" />
    </template>
    <template
      v-else-if="profile"
      class="container">
      <ProfileHeader
        class="mb-4"
        :profile="profile" />

      <Section
        v-if="profile.skills && profile.skills.length > 0"
        title="Das kann ich">
        <div style="margin-bottom: -.5rem;">
          <Skill
            class="me-2 mb-2"
            v-for="skill in profile.skills"
            :key="skill.skill.id"
            :profileSkill="skill"
            :showLevel="true" />
        </div>
      </Section>

      <Section
        v-if="profile.searchtopics && profile.searchtopics.length > 0"
        title="Ich suche für mich Projekte/Aufträge in folgenden Bereichen">
        <div style="margin-bottom: -.5rem;">
          <Skill
            class="me-2 mb-2"
            v-for="skill in profile.searchtopics"
            :key="skill.skill.id"
            :profileSkill="skill"
            :showLevel="false" />
        </div>
      </Section>

      <Section
        v-if="profile.languages && profile.languages.length > 0"
        title="Ich spreche diese Sprachen">
        <div style="margin-bottom: -.5rem;">
          <Language
            class="me-2 mb-2"
            v-for="language in profile.languages"
            :key="language.language.id"
            :profileLanguage="language"
          />
        </div>
      </Section>

      <Section title="Verfügbarkeit">
        <div class="d-flex align-items-center">
          <div v-if="profile.availability_status">
            <i class="bi bi-check-square me-1"></i>
            ja
          </div>
          <div v-else>
            <i class="bi bi-x-square me-1"></i>
            nein
          </div>
          <span
            class="ms-3"
            v-if="profile.availability_status && profile.availability_hours_per_week">
            ({{ profile.availability_hours_per_week }} Stunden pro Woche)
          </span>
        </div>
        <div v-if="profile.availability_text" class="mt-3">
          <label class="form-label fw-bold">
            Anmerkungen
          </label>
          <div class="line-break-text">{{ profile.availability_text }}</div>
        </div>
      </Section>

      <Section
        v-if="profile.contacts && profile.contacts.length > 0"
        title="Meine Kontaktmöglichkeiten"
      >
        <div style="margin-bottom: -.5rem;">
          <Contact
            class="me-2 mb-2"
            v-for="profileContact in profile.contacts"
            :key="profileContact.id"
            :profileContact="profileContact"
          />
        </div>
      </Section>

      <Section
        v-if="profile.volunteerwork || profile.freetext"
        title="Sonstiges">
        <div v-if="profile.freetext" :class="{ 'lh-base': true, 'mb-4': profile.volunteerwork }">
          <h5>Über mich</h5>
           <div class="line-break-text">{{ profile.freetext }}</div>
        </div>
        <div v-if="profile.volunteerwork" class="lh-base">
          <h5>Ehrenamtliche Arbeit</h5>
          <div class="line-break-text">{{ profile.volunteerwork }}</div>
        </div>
      </Section>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import ViewError from '@/components/ViewError'
import ProfileHeader from '@/components/profile/Header'
import Section from '@/components/profile/Section'
import Contact from '@/components/profile/Contact'
import Language from '@/components/profile/Language'
import Skill from '@/components/Skill'

export default {
  name: 'profileView',
  components: {
    Contact,
    Language,
    ProfileHeader,
    Section,
    Skill,
    ViewError,
  },
  methods: {
    ...mapActions({
      loadProfile: 'profile/load',
      clearStore: 'profile/clear',
    })
  },
  computed: {
    ...mapState({
      profile: state => state.profile.profile,
      error: state => state.profile.error,
      notFound: state => state.profile.notFound,
      isOwnProfile: state => state.profile.isOwnProfile,
      showSpinner: state =>  state.profile.showSpinner
    })
  },
  async created() {
    const id = parseInt(this.$route.params.memberId, 10)
    this.loadProfile(id)
  },
  unmounted() {
    this.clearStore()
  },
};
</script>

<style>
.line-break-text{
  white-space: pre-line;
}
</style>
