// SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>
//
// SPDX-License-Identifier: AGPL-3.0-or-later

export default {
  namespaced: true,
  state() {
    return {
      searching: false,
      showSpinner: false,
      searched: false,
      profiles: [],
      error: false,
      errorMessage: '',
      pages: 1,
      query: {
        search: '',
        page: 1
      }
    }
  },
  mutations: {
    setSearching(state, searching) {
      state.searching = searching
    },
    showSpinner(state) {
      state.showSpinner = true
    },
    hideSpinner(state) {
      state.showSpinner = false
    },
    clearProfiles(state) {
      state.profiles = []
    },
    setProfiles(state, profiles) {
      state.profiles = profiles
    },
    setError(state, error) {
      state.error = error
    },
    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage
    },
    setQuerySearch(state, search) {
      state.query = {...state.query, search}
    },
    setPages(state, pages) {
      state.pages = pages
    },
    setQueryPage(state, page) {
      state.query = {...state.query, page}
    }
  },
  actions: {
    async search({state, commit, rootState, dispatch}) {
      if (state.searching) {
        return
      }

      commit('setSearching', true)

      const timeoutId = setTimeout(() => {
        commit('showSpinner')
        commit('clearProfiles')
      }, 100)

      commit('setError', false)
      commit('setErrorMessage', '')

      const url = new URL(`${window.ki.apiUrl}/users/profiles`)

      if (state.query.search) {
        url.searchParams.append('search', state.query.search)
      }

      url.searchParams.append('page', state.query.page)

      const headers = {
        Authorization: `Bearer ${rootState.token}`,
      }

      let response

      try {
        response = await fetch(url, {headers})
      } catch {
        commit('setError', true)
        commit('clearProfiles')
        commit('setSearching', false)
        commit('hideSpinner')
        return
      }

      console.log(response.ok)
      console.log(response.status)
      console.log(state.query.page)

      clearTimeout(timeoutId)

      if (!response.ok && response.status === 404 && state.query.page != 1) {
        commit('setQueryPage', 1)
        commit('setSearching', false)
        await dispatch('search')
        return
      }

      if (!response.ok) {
        commit('setError', true)
        commit('clearProfiles')
        commit('setSearching', false)
        commit('hideSpinner')
        return
      }

      const responseData = await response.json()
      commit('setProfiles', responseData.profiles)
      commit('setPages', responseData.pages)
      commit('setSearching', false)
      commit('hideSpinner')
    }
  }
}
