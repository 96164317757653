<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <profile-list
    :values="values"
    :type="type"
    :editable="true"
    :show-secondary="showSecondary"
    @remove-value="removeValue($event)"
    @update-values="this.$emit('update-values', this.values)"
  >
  </profile-list>
  <div v-bind="$attrs" class="card-body bg-white">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-3 col-xl-2">
        <div class="form-control-plaintext form-control-sm">Eintrag hinzufügen:</div>
      </div>
      <div class="col-12 col-md-6">
        <input
          autocomplete="off"
          type="text"
          class="form-control form-control-sm"
          id="searchText"
          :maxlength="maxlength"
          :placeholder="placeholder"
          v-model="searchText"
          @input="search()"
          @keyup.enter="addResult()"
        />
        <div v-if="searchResults">
          <ul class="list-group">
            <li
              class="list-group-item bg-white"
              v-for="result in searchResults"
              :key="result.id"
              @click="addResult(result)"
            >
              {{ result.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-2">
        <button
          v-if="searchText != ''"
          type="button"
          class="btn btn-outline-success"
          aria-label="Hinzufügen"
          @click="addResult()"
        >
          <i clas="bi-plus-lg"></i>
          Hinzufügen
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import RequestMixin from '@/mixins/request.mixin'
import ProfileList from '@/components/ProfileList';

export default {
  name: 'AutoComplete',
  mixins: [RequestMixin],
  components: {
    ProfileList,
  },
  props: {
    type: {
      type: String,
    },
    label: {
      type: String,
    },
    values: {
      type: Array,
    },
    showSecondary: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      iconUrl: this.apiUrl,
      searchText: '',
      searchResults: [],
      showErrorMessage: false,
    };
  },
  computed: {
    ...mapState(['currentUserId']),
    maxlength() {
      return this.type === 'skill' ? 50 : 25
    }
  },
  methods: {
    addResult(result = false) {
      if (!result) result = this.searchResults[0];

      if (
        this.values.map((item) => item[this.type].name).includes(result.name)
      ) {
        return false;
      }

      let changeValues = Object.assign(this.values);
      let newValue = {
        profile_id: this.currentUserId,
      };

      if (this.type != 'contacttype') {
        newValue.level = 1;
      } else {
        newValue.content = '';
      }

      newValue[this.type] = result;
      changeValues.unshift(newValue);

      this.searchText = '';
      this.searchResults = [];
      this.$emit('update-values', changeValues);
    },
    removeValue(valueName) {
      const newValues = this.values.filter((value) => {
        if (valueName === value[this.type].name) {
          return false;
        } else {
          return true;
        }
      });
      this.$emit('update-values', newValues);
    },
  },
};
</script>
