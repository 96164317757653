// SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>
//
// SPDX-License-Identifier: AGPL-3.0-or-later

import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import store from '@/store'
import VTooltipPlugin from 'v-tooltip'

import 'v-tooltip/dist/v-tooltip.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './assets/global.scss'

const app = createApp(App)

app.use(VTooltipPlugin)
app.use(router)
app.use(store)

app.config.globalProperties.apiUrl = window.ki.apiUrl

app.mount('#app')
