<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="container mb-5">
    <h3 class="text-center">
      {{ title }}
    </h3>
    <div class="card w-100">
      <slot name="card-body">
        <div class="card-body bg-white">
          <slot></slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>
