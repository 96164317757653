<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <header v-if="this.$route.path.includes('/s/')">
    <Navbar />
  </header>
  <router-view :key="$route.fullPath" />
  <Footer />
</template>
<script>
import Footer from '@/components/Footer'
import Navbar from '@/components/Navbar'

export default {
  name: 'App',
  components: {
    Footer,
    Navbar,
  }
};
</script>
