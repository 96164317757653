<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
      <router-link
        class="navbar-brand"
        :to="{ path:'/s/search' }"
      >
        <img class="wtf-logo wtf-logo--navbar" src="@/assets/img/wtf_logo.svg">
        <span class="d-none d-sm-inline">Kompetenzinventar</span>
        <span class="d-sm-none">KI</span>
      </router-link>
      <button
        @click="toggleMobileNav"
        class="navbar-toggler"
        type="button"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse d-lg-flex"
        :class="{ show: showMobileNav }"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav mb-2 mb-lg-0 me-auto">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ path: `/s/search` }"
              active-class="active"
            >Suche</router-link
          >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ path: `/s/profile/${currentUserId}` }"
              active-class="active"
            >Mein Profil</router-link
          >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :to="{ path: `/s/profile-edit` }"
              active-class="active"
            >Bearbeiten</router-link
          >
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <button class="btn btn-danger w-100" @click="logout()">
              <i class="bi bi-box-arrow-right"></i>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

import { mapState } from 'vuex'

import RequestMixin from '@/mixins/request.mixin'

export default {
  name: 'Navbar',
  mixins: [RequestMixin],
  data() {
    return {
      showMobileNav: false
    }
  },
  computed: {
    ...mapState(['currentUserId'])
  },
  methods: {
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav
    },
    logout() {
      this.$store.dispatch('clear')
      this.$router.push({ path: '/' });
    },
  }
}

</script>

<style>

.wtf-logo--navbar {
  height: 40px;
  margin-bottom: -5px;
  margin-top: -5px;
}

</style>
