<!--
SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>

SPDX-License-Identifier: AGPL-3.0-or-later
-->

<template>
  <div class="avatar bg-dark">
    {{ avatarLetters }}
  </div>
</template>

<script>
export default {
  props: {
    name: String
  },
  computed: {
    avatarLetters() {
      return this.name.substr(0, 2)
    }
  }
}
</script>

<style scoped>
.avatar {
  align-items: center;
  border-radius: .25rem;
  color: white;
  display: flex;
  font-weight: bold;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
}
</style>
