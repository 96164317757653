// SPDX-FileCopyrightText: WTF Kooperative eG <https://wtf-eg.de/>
//
// SPDX-License-Identifier: AGPL-3.0-or-later

import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'

import Index from '@/views/Index.vue'
import Search from '@/views/Search.vue'
import Edit from '@/views/profile/Edit.vue'
import View from '@/views/profile/View.vue'

const routes = [
  {
    path: '/s',
    name: 's',
    component: {
      template: "<router-view/>",
    },
    beforeEnter: (to, from, next) => {
      if (store.state.token){
        next()
      } else {
        next({path: '/', query: {url: to.fullPath, access: false}})
      }
    },
    children: [
      {
        path: 'search',
        name: 'Search',
        component: Search
      },
      {
        path: 'profile/:memberId',
        name: 'ProfileMember',
        component: View
      },
      {
        path: 'profile-edit',
        name: 'ProfileEdit',
        component: Edit
      },
    ]
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    beforeEnter: (_to, _from, next) => {
      if (store.state.token) {
        next({name: 'Search'})
      } else {
        next()
      }
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
